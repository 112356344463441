<style scoped></style>

<template>
  <fm-down-select @change="onChange" :value="value" :confirm="confirm" :options="options" :clearable="false">
    <span style="cursor: pointer;">{{data}} <i class="fmico fmico-paixu-jiangxu" style="margin-left: 5px;"></i></span>
  </fm-down-select>
</template>

<script>
const statusList = Object.freeze([
  {
    label: '待处理',
    value: '待处理',
    cls: 'g-label-badge-info',
    status: ['规划中', '执行中', '待确认']
  },
  {
    label: '已完成',
    value: '已完成',
    cls: 'g-label-badge-success',
    status: ['已完成', '作废']
  }
])
export default {
  name: 'task-status-scene',
  data () {
    return {
      data: this.value
    }
  },
  props: {
    confirm: { type: Boolean, default: true },
    value: { type: String, default: '待处理' }
  },
  computed: {
    options () {
      return statusList.map(status => {
        return Object.assign({
          render (status) {
            return '<div class="' + status.cls + '">' + status.label + '</div>'
          }
        }, status)
      })
    }
  },
  methods: {
    onChange (data) {
      this.data = data
      this.$emit('change', statusList.find(v => v.value === data).status)
    }
  }
}
</script>
